import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Бүртгэл",
  "description": null,
  "author": "OTC help",
  "category": "account-management",
  "date": "2022-03-24T00:00:00.000Z",
  "featured": true,
  "tags": [],
  "order": 1
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Бүртгэл`}</h2>
    <p>{`Энэхүү Биржийн бус зах зээлийн OTC.MN платформд зөвхөн Монголын Үнэт Цаасны Арилжаа Эрхлэгчдийн Холбооны Бүртгэлийн хороонд хүсэлт гарган бүртгүүлсэн эрх бүхий шууд оролцогч нэвтрэх эрхтэй.`}</p>
    <p>{`Шууд оролцогчоор бүртгүүлэх тухай заавартай `}<a parentName="p" {...{
        "href": "http://http://masd.mn/upload/otc/sho_burtgel.pdf",
        "title": "энд"
      }}>{`энд`}</a>{` дарж танилцана уу.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      